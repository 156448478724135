import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect, lazy, Suspense } from 'react';

import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Footer from './Components/Footer/Footer';
import { ContactUs } from './Components/Contact/ContactUs';
import ProductDetail from './Components/productsCard/ProductDetail';
import Loading from './Components/Loading/Loading';
import SSTank from './Components/productsCard/SSTank ';
import CommercialROSystem from './Components/productsCard/CommercialROSystem/CommercialROSystem';

function App() {
  const [loading, setLoading] = useState(true); 

   
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000); 

        return () => clearTimeout(timer);
    }, []);

    const Home = lazy(() => import('./Components/Home/Home'));
  return (
    <div className="App">
     
                <>
      <Navbar/>
      <Suspense fallback={<div><Loading /></div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/products/:productName" element={<ProductDetail />} />
              <Route path="/products/sstank" element={<SSTank />} />
              <Route path="/products/pentair" element={<CommercialROSystem />} />
            </Routes>
          </Suspense>
      <Footer/>
      </>
          
    </div>
  );
}

export default App;
