import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
const firebaseConfig = {

  apiKey: "AIzaSyDM4FnmyiI24JeNjFzAdrLCul3hMKLx-tk",
  authDomain: "secondpundit.firebaseapp.com",
  projectId: "secondpundit",
  storageBucket: "secondpundit.appspot.com",
  messagingSenderId: "665522742863",
  appId: "1:665522742863:web:601255fb36c15b2badec3a",
  databaseURL: "https://secondpundit-default-rtdb.firebaseio.com",
};

const app = initializeApp(firebaseConfig);
const textdb = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const database = getDatabase(app);
auth.settings.appVerificationDisabledForTesting = true;

export { textdb, storage, auth, app, database };
export default firebaseConfig;




