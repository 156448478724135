import React, { lazy, Suspense } from 'react';

const Carousel = lazy(() => import('../Carousal/Carousel'));
const ProductsCard = lazy(() => import('../productsCard/ProductsCard'));
const Facts = lazy(() => import('../Facts/Facts'));
const Testimonial = lazy(() => import('../Testomonial/Testimonial'));
const Text = lazy(() => import('../Text/Text'));

const Home = () => {
  return (
    <div>
      <Suspense fallback={<div>Loading Carousel...</div>}>
        <Carousel />
      </Suspense>
      <Suspense fallback={<div>Loading Products...</div>}>
        <ProductsCard />
      </Suspense>
      <Suspense fallback={<div>Loading Facts...</div>}>
        <Facts />
      </Suspense>
      <Suspense fallback={<div>Loading Text...</div>}>
        <Text />
      </Suspense>
      <Suspense fallback={<div>Loading Testimonial...</div>}>
        <Testimonial />
      </Suspense>
    </div>
  );
};

export default Home;
