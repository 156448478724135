// Footer.jsx

import React from 'react';
import { FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='main-footer'>
    <footer className='footer-container'>
      <div className='footer-logo'>
        <img src='/img/logo.jpeg' alt='Company Logo' />
        <p></p>
      </div>

      <div className='footer-links'>
        <h3>Helpful Links</h3>
        <ul>
          <li><a href='/'>Home</a></li>
          <li><a href='/about'>About Us</a></li>
          <li><a href='/'>Product</a></li>
          <li><a href='/contact'>Contact</a></li>
          
        </ul>
      </div>

      {/* <div className='footer-services'>
        <h3>Services</h3>
        <ul>
          <li><a href='/service1'>Service 1</a></li>
          <li><a href='/service2'>Service 2</a></li>
          <li><a href='/service3'>Service 3</a></li>
        </ul>
      </div> */}

      <div className='footer-contact'>
        <h3>Contact Us</h3>
        <p>C200 Ground floor Sangam Vihar New Delhi-110080</p>
        <p> <strong>Email:</strong> Info@elektraefflux.com</p>
      
        <p><strong>Phone:</strong>+91.9354274778</p>

      </div>

      <div className='footer-social'>
        <h3>Connect With Us</h3>
        <ul>
          <li><a href='https://www.facebook.com/share/15nTAa2Lgv/?mibextid=LQQJ4d' target='_blank' rel='noopener noreferrer'><FaFacebook /></a></li>
          {/* <li><a href='https://twitter.com' target='_blank' rel='noopener noreferrer'><FaTwitter /></a></li> */}
          <li><a href='https://www.instagram.com/elektraefflux?igsh=ZmwwZXJhbHo4bHFp' target='_blank' rel='noopener noreferrer'><FaInstagram /></a></li>
        </ul>
      </div>
      

    </footer>
   {/* <span  className='desginedby'> <p1> &copy;  <Link
  to="https://www.anantainfotech.com"
  target="_blank"
  rel="noopener noreferrer"
><b></b> </Link> </p1>  </span> */}

    </div>
  );
};

export default Footer;
