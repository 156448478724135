import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  const closeMenu = () => {
    setMenuOpen(false);
    setDropdownOpen(false); // Close dropdown when menu is closed
  };

  const toggleDropdown = () => {
    setDropdownOpen(prev => !prev);
  };

  // Handle clicks outside the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuOpen && !event.target.closest('.navbar-container')) {
        closeMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  // Handle scroll to toggle navbar class
  useEffect(() => {
    const handleScroll = () => {
      setShowImage(window.scrollY >= 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Close the menu on route change
  useEffect(() => {
    closeMenu();
  }, [location]); // This will run when the location changes

  const handleLinkClick = () => {
    if (window.innerWidth < 768) {
      closeMenu();
    }
  };

  return (
    <nav className={showImage ? 'navbar active' : 'navbar'}>
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          <img src="/img/logo.jpeg" alt="logo" />
        </Link>
        <div className="to-hire">
          <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            {menuOpen ? <FaTimes /> : <FaBars />}
          </div>
        </div>
        <ul className={`nav-menu ${menuOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <Link to="/" className="nav-link" onClick={handleLinkClick}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/about" className="nav-link" onClick={handleLinkClick}>
              About Us
            </Link>
          </li>
          <li className="nav-item dropdown">
            <div className="nav-link" >
              Products
            </div>
            <ul className='dropdown-menu'>
              <li className="dropdown">
                <Link className="dropdown-link">
                  Grundfos Pumps 
                </Link>
                <ul className="dropdown-menu dropdown-left">
                  <li className="dropdown">
                    <Link className="dropdown-link" >Domestic Building Services - Grundfos</Link>
                    <ul className="dropdown-menu dropdown-left">
                      <li><Link to="/pdf/CM Booster.pdf" target="_blank" className="dropdown-link" onClick={toggleDropdown}>CMB Booster Pumps Single</Link></li>
                      <li><Link to="/pdf/open well.pdf" target="_blank" className="dropdown-link">SmAT Sub Ogmwell Sub</Link></li>
                      <li><Link to="/pdf/CMBE Booster.pdf" target="_blank" className="dropdown-link">CMBE Pumps VFD</Link></li>
                      <li><Link to="/pdf/CMBE Twin booster.pdf" target="_blank" className="dropdown-link">CMBE Twin</Link></li>
                      <li><Link to="/" target="_blank" className="dropdown-link">AP Draner Pumps</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <Link className="dropdown-link">Residential Building Services - Grundfos</Link>
                    <ul className="dropdown-menu dropdown-left">
                      <li><Link to="/pdf/RBS Booklet 2022.pdf" target="_blank" className="dropdown-link">Grundfos Hydropneumatic System</Link></li>
                      <li><Link to="/pdf/RBS Booklet 2022.pdf" target="_blank" className="dropdown-link">CRE Pumps</Link></li>
                      <li><Link to="/pdf/RBS Booklet 2022.pdf" target="_blank" className="dropdown-link">SEG Cutter Pumps</Link></li>
                    </ul>
                  </li>
                  <li className="dropdown">
                    <Link className="dropdown-link">Industry Pl-J Water Treatment and OEM - Grundfos</Link>
                    <ul className="dropdown-menu dropdown-left">
                      <li><Link to="/" className="dropdown-link">CM, CME</Link></li>
                      <li><Link to="/" className="dropdown-link">CRI CRN, Vertical Pumps</Link></li>
                      <li><Link to="" className="dropdown-link">CRE VFD Vertical Pumps</Link></li>
                      <li><Link to="/" className="dropdown-link">SEG Cutter Pumps</Link></li>
                      <li><Link to="/" className="dropdown-link">DPWDWK-Drainer and Swaege Pumps</Link></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <Link to="/products/willo" className="dropdown-link">Wilo Pumps</Link>
              </li>
              <li className="dropdown">
                <Link className="dropdown-link">AO Smith Heat Pumps</Link>
                <ul className="dropdown-menu dropdown-left">
                  <li><Link to="/pdf/HeatPumpCatalogue.pdf" target="_blank" className="dropdown-link">300 Lit</Link></li>
                  <li><Link to="/pdf/HeatPumpCatalogue.pdf" target="_blank" className="dropdown-link">450 Lit</Link></li>
                </ul>
              </li>
              <li className="dropdown">
                <Link className="dropdown-link">Steipel Eltron Heat Pumps & Gysers
                </Link>
                <ul className="dropdown-menu dropdown-left">
                  <li><Link to="https://www.stiebel-eltron.in/en/products-solutions/dhw/dhw_heat_pumps/all-products.html" target="_blank" className="dropdown-link">HEAT PUMPS
                  </Link></li>
                  <li><Link to="https://www.stiebel-eltron.in/en/products-solutions/dhw/dhw_heat_pumps/all-products.htmlhttps://www.stiebel-eltron.in/en/products-solutions/dhw/dhw_heat_pumps/all-products.html" target="_blank" className="dropdown-link">GYESERS</Link></li>
                </ul>
              </li>
              <li className="dropdown">
                <Link to="/products/sstank" className="dropdown-link">SS Tanks</Link>
              </li>
              <li className="dropdown">
                <Link to='/products/pentair' className="dropdown-link">Commercial RO System- Pentair</Link>
              </li>
              <li className="dropdown">
                <Link to="/products/swimmingpool" className="dropdown-link">Swimming Pool Solutions</Link>
              </li>
              
              <li className="dropdown">
                <Link className="dropdown-link">Control Panels</Link>
                <ul className="dropdown-menu dropdown-left">
                  <li><Link to="/products/controlpanels" className="dropdown-link">PLC Panels</Link></li>
                  <li><Link to="/products/controlpanels" className="dropdown-link">Temperature Control Panels</Link></li>
                  <li><Link to="/products/controlpanels" className="dropdown-link">PLC Drive Panels</Link></li>
                  <li><Link to="/products/controlpanels" className="dropdown-link">PLC & HMI Panels</Link></li>
                  <li><Link to="/products/controlpanels" className="dropdown-link">PLC Drive Panels</Link></li>
                </ul>
              </li>
              <li className="dropdown">
                <Link className="dropdown-link">Water Treatment Panel</Link>
                <ul className="dropdown-menu dropdown-left">
                  <li><Link to="/products/watertreatment" className="dropdown-link">LT Panel</Link></li>
                  <li><Link to="/products/watertreatment" className="dropdown-link">Water Treatment Panels</Link></li>
                  <li><Link to="/products/watertreatment" className="dropdown-link">Water Treatment Plants Panels</Link></li>
                  <li><Link to="/products/watertreatment" className="dropdown-link">Fire Panel</Link></li>
                  <li><Link to="/products/watertreatment" className="dropdown-link">Water Treatment Plants Electrical Pane</Link></li>
                </ul>
              </li>
              <li className="dropdown">
                <Link to="/products/sterling" className="dropdown-link">Sterling Generators</Link>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-link" onClick={handleLinkClick}>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
