import React from 'react';
import './CommercialROSystem.css';

const CommercialROSystem = () => {
  return (
    <div className="commercial-ro-container">
      <h1>Commercial RO System - Pentair</h1>
      <p>
        Our Pentair Commercial RO System provides a premier water purification solution, ensuring safe and clean water
        for various commercial applications. At Electra Efflux, we are dedicated to offering top-quality products, and
        this reverse osmosis system effectively removes impurities and contaminants. With its reliable performance and
        scalability, the Pentair system is ideal for businesses such as restaurants and hotels. Minimal maintenance
        requirements and user-friendly controls enhance operational efficiency, making us a preferred choice for
        high-quality water solutions.
      </p>
      <img src="/img/rosystem.jpg" alt="Commercial RO System" className="commercial-ro-image" />
    </div>
  );
};

export default CommercialROSystem;
