import React from 'react';
import { FaLightbulb, FaGlobe, FaLeaf } from 'react-icons/fa';
import styles from './About.module.css'; 
import LogoMarqueeSection from './LogoMarqueeSection';

const About = () => {
  return (
    <div className={styles.aboutContainer}>
      {/* Hero Section */}
      <section className={styles.heroSection}>
        <div className={styles.heroContent}>
          <h1 className={styles.heroTitle}>Elektra Efflux</h1>
          <p className={styles.subheading}>
            A premier Water solution provider in India, delivering innovative and reliable water management solutions.
          </p>
          {/* <button className={styles.ctaButton}>Learn More</button> */}
        </div>
      </section>

      {/* About Section */}
      <section className={styles.aboutSection}>
        <div className={styles.aboutContent}>
          {/* <h2 className={styles.sectionTitle}>About Us</h2> */}
          <p>
          At Elektra Efflux, we pride ourselves on years of expertise in providing comprehensive water management solutions. With a strong foundation in industry knowledge, we’ve grown to become a trusted partner for businesses seeking reliable and innovative solutions in water pumps, treatment panels, and storage systems. Our team of experts is dedicated to understanding the unique requirements of each client, ensuring that our products not only meet industry standards but exceed customer expectations.

          </p>
          <p>
          Our commitment to quality and sustainability drives every decision we make. With a relentless focus on innovation, we continue to develop cutting-edge solutions that address the water challenges of today and tomorrow, helping businesses achieve sustainable growth through reliable and efficient systems.
          </p>
         
        </div>
      </section>

   
      <section className={styles.mvSection}>
        <div className={styles.mvContainer}>
          <div className={styles.mvCard}>
            <FaLightbulb className={styles.mvIcon} />
            <h3>Mission</h3>
            <p>
            Our mission is to provide innovative and reliable water management solutions that enhance efficiency and sustainability. We aim to meet the diverse needs of our clients across industries, delivering high-performance products and services that contribute to a better future for businesses and communities alike.
            </p>
          </div>
          <div className={styles.mvCard}>
            <FaGlobe className={styles.mvIcon} />
            <h3>Vision</h3>
            <p>
            Our vision is to be recognized as a global leader in water management and industrial solutions, setting new benchmarks for innovation and sustainability. We strive to continuously improve, embracing cutting-edge technologies and practices to create lasting value for our customers and partners around the world
            </p>
          </div>
          <div className={styles.mvCard}>
            <FaLeaf className={styles.mvIcon} />
            <h3>Values</h3>
            <p>
            We are committed to sustainability, promoting eco-friendly practices that enhance energy efficiency and reduce our environmental footprint. Our integrity ensures that we operate with transparency and honesty, fostering trust in all our relationships. We are customer-centric, placing our clients' needs at the forefront and tailoring our solutions to exceed their expectations.
            </p>
          </div>
        </div>
      </section>
      <div>
<LogoMarqueeSection/>
<p style={{color:"red"}}>The mentioned brands are not directly associated with us. Our work was limited to specific projects on their premises.</p>

      </div>
    </div>
  );
};

export default About;
