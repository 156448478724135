import React from 'react';
import './LogoMarqueeSection.css';

const logos = [

 "/p/unitech.png",
 "/p/sobha.png",
 "/p/puravankara.png",
 "/p/purankara.png",
 "/p/prestige.png",
 "/p/omax.png",
 "/p/godrage.png",
 "/p/dlf.png",
 "/p/bridge.png",
 "/p/ansal.png",
 

];

const LogoMarqueeSection = () => {
  return (
    <section className="logoMarqueeSection" style={{ backgroundColor: '#08628F' }}>
      <div className="container" id="logoMarqueeSection">
        <div className="default-content-container flex items-center">
          <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
            <div className="marquee" style={{ animationDuration: '57s' }}>
              {logos.map((logo, index) => (
                <a href="#" target="_blank" key={index}>
                  <img src={logo} alt="" className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} />
                </a>
              ))}
            </div>
            <div className="marquee" style={{ animationDuration: '57s' }}>
              {logos.map((logo, index) => (
                <a href="#" target="_blank" key={index + logos.length}>
                  <img src={logo} alt="" className="marqueelogo" style={{ width: 'auto', maxWidth: 'none' }} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LogoMarqueeSection;
