import React from 'react';
import './SSTank.css';

const SSTank = () => {
  return (
    <div className="sstank-container">
      <header className="sstank-header">
        <h1>SS Tanks</h1>
        <p>
          Our stainless steel (SS) tanks are an essential part of water storage solutions at Elektra Efflux. Known for
          their durability and resistance to corrosion, these tanks are perfect for various applications, including
          industrial, commercial, and residential use. The smooth internal surface prevents bacterial growth, ensuring
          the safety and quality of stored water. Available in various sizes, our SS tanks can accommodate diverse
          storage needs while offering easy installation and low maintenance. Trust our expertise for reliable and
          efficient water storage solutions tailored to your requirements.
        </p>
      </header>

      <section className="sstank-series">
        <h2>Comfort Series</h2>
        <div className="sstank-image-grid">
          <a href="https://kingpure.in/" target="_blank" rel="noopener noreferrer">
            <img src="/img/ss tanks/10.png" alt="Comfort Series" />
          </a>
          <a href="https://kingpure.in/" target="_blank" rel="noopener noreferrer">
            <img src="/img/ss tanks/11.png" alt="Comfort Series" />
          </a>
          <a href="https://kingpure.in/" target="_blank" rel="noopener noreferrer">
            <img src="/img/ss tanks/12.png" alt="Comfort Series" />
          </a>
        </div>
      </section>

      <section className="sstank-series">
        <h2>Premium Series</h2>
        <div className="sstank-image-grid">
          <a href="https://kingpure.in/" target="_blank" rel="noopener noreferrer">
            <img src="/img/ss tanks/13.png" alt="Premium Series" />
          </a>
          <a href="https://kingpure.in/" target="_blank" rel="noopener noreferrer">
            <img src="/img/ss tanks/14.png" alt="Premium Series" />
          </a>
          <a href="https://kingpure.in/" target="_blank" rel="noopener noreferrer">
            <img src="/img/ss tanks/15.png" alt="Premium Series" />
          </a>
        </div>
      </section>

      <section className="sstank-series">
        <h2>Luxury Series</h2>
        <div className="sstank-image-grid">
          <a href="https://kingpure.in/" target="_blank" rel="noopener noreferrer">
            <img src="/img/ss tanks/16.png" alt="Luxury Series" />
          </a>
          <a href="https://kingpure.in/" target="_blank" rel="noopener noreferrer">
            <img src="/img/ss tanks/17.png" alt="Luxury Series" />
          </a>
          <a href="https://kingpure.in/" target="_blank" rel="noopener noreferrer">
            <img src="/img/ss tanks/18.png" alt="Luxury Series" />
          </a>
        </div>
      </section>
    </div>
  );
};

export default SSTank;
