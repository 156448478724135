import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { textdb } from '../../firebaseConfig'; 
import './ContactUs.css';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

export const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [quoteDetails, setQuoteDetails] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const [quoteSubmitted, setQuoteSubmitted] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuoteDetails({ ...quoteDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
    try {
      const docRef = await addDoc(collection(textdb, 'quotes'), quoteDetails);
      console.log('Document written with ID: ', docRef.id);
      setQuoteSubmitted(true);
      setQuoteDetails({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
      });
      toast.success("Message submitted successfully");
    } catch (error) {
      console.error('Error adding document: ', error);
      toast.error("Error submitting message");
    }
    setUploading(false);
  };

  const getQuotesFromFirebase = async () => {
    const querySnapshot = await getDocs(collection(textdb, 'quotes'));
    const quotesData = [];
    querySnapshot.forEach((doc) => {
      quotesData.push({ id: doc.id, ...doc.data() });
    });
    setQuotes(quotesData);
  };

  useEffect(() => {
    getQuotesFromFirebase();
  }, []);

  return (
    <div style={{ marginTop: "80px" }} className="main-contact">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta name="description" content="We're Always Eager to Hear From You!" />
      </Helmet>

      {/* Hero Section */}
      <div className="hero-section">
        <div className="hero-text-overlay">
          <h1>We're Here to Help</h1>
          <p>Contact us today for personalized support and solutions.</p>
        </div>
      </div>

      <div className="main-farm">
        <div className="loginimg">
          <h2>Get in touch with us today</h2>
          <p>To learn more about our products and how we can assist you with your water management needs. Our team is ready to provide expert consultation and support.</p>
          <b>Address</b>
          <p>C200 Ground floor Sangam Vihar New Delhi-110080</p>
          <b>Email</b>
          <p>nfo@elektraefflux.com</p>
          <b>Phone</b>
          <p>+91.9354274778</p>
        </div>

        <div data-aos="fade-right" className="inputform">
          <h2>Get In Touch</h2>
          <p>Fill out this form for booking a consultant advising session.</p>
          <form onSubmit={handleSubmit}>
            <label>Name:</label>
            <input
              className="mes"
              type="text"
              name="name"
              value={quoteDetails.name}
              onChange={handleChange}
              required
            />
            <label>Email:</label>
            <input
              className="mes"
              type="email"
              name="email"
              value={quoteDetails.email}
              onChange={handleChange}
              required
            />
            <label>Phone Number:</label>
            <input
              className="mes"
              type="text"
              name="phoneNumber"
              value={quoteDetails.phoneNumber}
              onChange={handleChange}
              required
            />
            <label>Message:</label>
            <textarea
              className="mes"
              name="message"
              value={quoteDetails.message}
              onChange={handleChange}
              required
            ></textarea>
            <button className="button" type="submit">
              {uploading ? "Submitting..." : "Submit Message"}
            </button>
          </form>
        </div>
      </div>

      <div className="contact-map">
        <iframe
          width="100%"
          height="650px"
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d124858.95546230547!2d82.70898586341067!3d21.12332336533662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3b9de0c149f%3A0x17faea10fa3e2ac3!2sELEKTRA%20EFFLUX!5e0!3m2!1sen!2sin!4v1731588158606!5m2!1sen!2sin"
          className="map-contact"
        ></iframe>
      </div>
    </div>
  );
};
