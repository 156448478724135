// src/Components/Loading.js
import React from 'react';
import './Loading.css';

const Loading = () => {
    return (
        <div className='lodermain'>
        <div className="loader">
          
        </div>
        </div>
    );
};

export default Loading;
