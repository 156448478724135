// ProductDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetail.css';

const productDetails = {
    grundfos: {
        name: 'Grundfos Pumps',
        image: '/img/Grundfos Pumps.png',
        description: 'At Elektra Efflux, we offer Grundfos pumps, celebrated for their exceptional reliability and energy efficiency. These pumps cater to a wide range of applications, including residential, commercial, and industrial needs, ensuring consistent water flow and pressure management. With advanced technology at their core, Grundfos pumps are designed to optimize performance while minimizing energy consumption. Our commitment to quality positions us as a top choice for customers seeking high-performance pumping solutions. Whether you need pumps for irrigation, water supply, or HVAC systems, our Grundfos products deliver unmatched durability and efficiency.',
    },
    willo: {
        name: 'Wilo Pumps',
        image: '/img/willo.png',
        description: 'As a premier supplier in the water management sector, Elektra Efflux is proud to present Wilo pumps, renowned for their innovative designs and outstanding efficiency. These versatile pumps are suitable for various applications, from building services to industrial processes, providing reliable operation and reduced operating costs. With a focus on sustainability, Wilo pumps help lower energy consumption, making them an eco-friendly option for our clients. Our team of experts ensures seamless installation and ongoing maintenance, further enhancing our status as a trusted provider of water management solutions.',
    },
    aosmith: {
        name: 'AO Smith Heat Pumps',
        image: '/img/aosmith.png',
        description: 'For efficient water heating solutions, Elektra Efflux is your go-to provider of AO Smith heat pumps. These cutting-edge heat pumps utilize ambient air to deliver hot water efficiently, significantly reducing energy costs. Their compact design makes them suitable for both residential and commercial settings, ensuring a reliable supply of hot water. With user-friendly controls and durable construction, AO Smith heat pumps stand out for their reliability and performance. Count on us for superior heating solutions that enhance your comfort while showcasing our expertise in the industry.',
    },
    stiebel: {
        name: 'Stiebel Eltron Geysers',
        image: '/img/stiebel.png',
        description: 'At Elektra Efflux, we offer Stiebel Eltron geysers, designed for high performance and energy efficiency. These geysers feature advanced insulation technology to minimize heat loss and reduce energy consumption, making them an excellent choice for both residential and commercial applications. Their sleek design seamlessly integrates into any environment while providing a reliable hot water supply. Trust our team to deliver quality heating solutions that ensure your satisfaction and comfort, reinforcing our position as a leader in water management solutions.',
    },
    pentair: {
        name: 'Commercial RO System - Pentair',
        image: '/img/ro.png',
        description: 'Our Pentair Commercial RO System provides a premier water purification solution, ensuring safe and clean water for various commercial applications. At Elektra Efflux, we are dedicated to offering top-quality products, and this reverse osmosis system effectively removes impurities and contaminants. With its reliable performance and scalability, the Pentair system is ideal for businesses such as restaurants and hotels. Minimal maintenance requirements and user-friendly controls enhance operational efficiency, making us a preferred choice for high-quality water solutions.',
    },
    swimmingpool: {
        name: 'Swimming Pool Solutions',
        image: '/img/swimming pool pumps.png',
        description: 'Elektra Efflux offers comprehensive swimming pool solutions, providing state-of-the-art filtration systems, chemical dosing equipment, and heating solutions to maintain pristine water quality. We understand the importance of a safe and enjoyable swimming environment, and our tailored solutions meet the unique needs of each pool. Our expert team ensures efficient installation and maintenance, enhancing the overall experience for pool owners. Choose us to keep your pool clean and inviting, and enjoy our commitment to excellence in water management.',
    },
    sstanks: {
        name: 'SS Tanks',
        image: '/img/ss tank.png',
        description: 'Our stainless steel (SS) tanks are an essential part of water storage solutions at Elektra Efflux. Known for their durability and resistance to corrosion, these tanks are perfect for various applications, including industrial, commercial, and residential use. The smooth internal surface prevents bacterial growth, ensuring the safety and quality of stored water. Available in various sizes, our SS tanks can accommodate diverse storage needs while offering easy installation and low maintenance. Trust our expertise for reliable and efficient water storage solutions tailored to your requirements.',
    },
  
    sterling: {
        name: 'Sterling Generators',
        image: '/img/Sterling Generators.png',
        description: 'Our Sterling generators are a vital part of our offerings at Elektra Efflux, ensuring dependable power solutions for our clients. These generators are engineered for reliable performance, providing uninterrupted power during outages for residential and commercial applications. With various capacities available, our Sterling generators meet different power requirements. Their user-friendly design ensures easy operation and maintenance, guaranteeing readiness when you need it most. Invest in our generators for continuous power supply and experience the assurance that comes with choosing a trusted partner in your energy needs.',
    },
    thermostatic: {
        name: 'Thermostatic Mixers',
        image: 'path/to/thermostatic.jpg',
        description: 'At Elektra Efflux, we provide ABB and Schneider switchgear solutions that ensure safe and efficient electrical power distribution. Our products excel in controlling and protecting electrical systems, making them essential for industrial and commercial applications. Designed to minimize electrical faults and ensure reliable operation, our switchgear is compliant with industry standards. Customizable to meet specific application needs, these solutions enhance the safety and performance of power distribution systems. Trust our expertise in providing high-quality switchgear to keep your operations running smoothly.',
    },
    sstanks: {
        name: 'SS Tankss',
        description: 'Our stainless steel (SS) tanks are an essential part of water storage solutions at Elrktra Efflux. Known for their durability and resistance to corrosion, these tanks are perfect for various applications, including industrial, commercial, and residential use. The smooth internal surface prevents bacterial growth, ensuring the safety and quality of stored water. Available in various sizes, our SS tanks can accommodate diverse storage needs while offering easy installation and low maintenance. Trust our expertise for reliable and efficient water storage solutions tailored to your requirements.',

        sections: [
            {
                title: 'Comfort Series', 
                image: '/img/controlpannel/1.png',
                description: 'Elektra Efflux offers precision-engineered PLC Panels, designed to automate and streamline industrial processes with advanced programmability and seamless integration capabilities. These panels provide robust control solutions across various applications, ensuring efficiency, reliability, and operational control for complex industrial needs.',
            },
            
            
        ],
    },
    controlpanels: {
        name: 'Control Panels',
        sections: [
            {
                title: 'PLC Panels',
                image: '/img/controlpannel/1.png',
                description: 'Elektra Efflux offers precision-engineered PLC Panels, designed to automate and streamline industrial processes with advanced programmability and seamless integration capabilities. These panels provide robust control solutions across various applications, ensuring efficiency, reliability, and operational control for complex industrial needs.',
            },
            {
                title: 'Temperature Control Panels',
                image: '/img/controlpannel/2.png',
                description: 'Our Temperature Control Panels are crafted to maintain and monitor precise temperature levels, ensuring optimal conditions for equipment and processes. These panels are equipped with high-quality components and easy-to-use interfaces, making them ideal for industries where temperature regulation is critical to productivity and safety',
            },
            {
                title: 'PLC Drive Panels',
                image: '/img/controlpannel/3.png',
                description: '- PLC Drive Panels deliver accurate speed and torque control for motors, ensuring smooth and efficient operation. Built with top-tier components, these panels are perfect for applications where variable motor control enhances energy efficiency and process accuracy, adapting effortlessly to diverse industrial demands.',
            },
            {
                title: 'PLC & HMI Panels ',
                image: '/img/controlpannel/4.png',
                description: '- Elektra Efflux combines the power of PLC and HMI technology in our PLC & HMI Panels, providing real-time monitoring and intuitive control over processes. These panels enable operators to interact seamlessly with machinery, ensuring smooth functionality and quick responses to any adjustments needed for optimal performance.',
            },
            {
                title: 'Meter Panels ',
                image: '/img/controlpannel/5.png',
                description: 'Elektra Efflux provides high-quality Meter Panels designed to accurately monitor and display electrical parameters, ensuring comprehensive visibility into power consumption and system health. These panels are essential for energy management, allowing precise measurement and enabling better control over energy usage in various industrial and commercial settings.',
            },
            
        ],
    },
    watertreatment: {
        name: 'Water Treatment Panel',
        sections: [
            {
                title: 'LT Panel',
                image: '/img/Water Treatment Panel/1.png',
                description: '- Elektra Efflux provides LT Panels (Low Tension Panels) that are ideal for distributing power safely in industrial and commercial setups. Built with durability and reliability in mind, these panels manage low voltage distribution, safeguarding equipment and optimizing energy flow throughout facilities.',
            },
            {
                title: 'Water Treatment Panels',
                image: '/img/Water Treatment Panel/2.png',
                description: 'Water Treatment Panels are engineered to support efficient water treatment processes, offering precise control and monitoring. These panels are essential in managing water purification systems, ensuring consistent water quality and seamless integration with other treatment plant components.',
            },
            {
                title: 'Water Treatment Plants Panels',
                image: '/img/Water Treatment Panel/3.png',
                description: 'Tailored for comprehensive water treatment operations, Elektra Efflux’s Water Treatment Plants Panels offer centralized control over multiple processes. These panels streamline the treatment process, ensuring quality, safety, and efficiency in water management for large-scale operations.',
            },
            {
                title: 'Fire Panel',
                image: '/img/Water Treatment Panel/4.png',
                description: 'Elektra Efflux’s Fire Panels are designed for rapid response and monitoring in fire safety systems. Equipped with advanced detection and alarm systems, these panels are essential for ensuring safety and swift action in emergency scenarios, making them crucial for industrial and commercial buildings.',
            },
            {
                title: 'Water Treatment Plants Electrical Pane ',
                image: '/img/Water Treatment Panel/5.png',
                description: 'Water Treatment Plants Electrical Panels, specifically designed to support electrical control and distribution in water treatment facilities. These panels provide reliable power management, ensuring uninterrupted operation and the seamless integration of all treatment plant systems.',
            },
           
           
        ],
    },
};

const ProductDetail = () => {
    const { productName } = useParams();

    if (!productName) {
        return <p>Product name is missing in the URL.</p>;
    }

    const product = productDetails[productName.toLowerCase()];

    if (!product) {
        return <p>Product not found.</p>;
    }

    return (
        <div className="product-detail">
            <h2>{product.name}</h2>
            {product.sections ? (
                product.sections.map((section, index) => (
                    <div
                        key={index}
                        className={`product-section ${index % 2 === 0 ? 'left' : 'right'}`}
                    >
                        <img src={section.image} alt={section.title} className="product-section-image" />
                        <div className="product-section-content">
                            <h3>{section.title}</h3>
                            <p>{section.description}</p>
                        </div>
                    </div>
                ))
            ) : (
                <div className="product-section">
                    <img src={product.image} alt={product.name} className="product-section-image" />
                    <div className="product-section-content">
                        <p>{product.description}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductDetail;
